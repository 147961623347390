<template>
	<div class="push-box ">
		<div v-for="(item, index) in yesterdayPushedList"
		     :key="item.msgId"
		     class="item">
			<img class="headImg" :src="robotInfo.headImgUrl" alt=""/>
			<div class="text">
				<div class="name">
					<p>{{ robotInfo.nickName }}</p>
				</div>
        <friend-info :item="item" />
        <div class="time">
					<p :class="item.stateColor">
						{{ item.stateName }}
						{{ item.createTime }}
					</p>
					<template v-if="![1,2,3].includes(item.state)">
										<span class="mr-2 fs-12"
										      :class="item.retryCount === 0 ? 'color-primary' : 'color-black'"
										      @click="item.retryCount === 0 ? handleResend(item) : ''">
											{{ item.retryCount === 0 ? (item.state === 4 ? '立即发送' : '重新发送') : '已重新发送' }}
										</span>
					</template>
				</div>
			</div>

		</div>

	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ImagePreview } from 'vant'
import { Notify } from 'vant'
import { ApiFriendCircleRetrySend } from '@/api/FriendCircle'
import FriendInfo from "../../components/FriendInfo.vue";
export default {
	name: 'yesterdayPush',
	components: {
    FriendInfo,
	},
	computed: {
		...mapGetters(['yesterdayPushedList', 'robotInfo'])
	},
	mounted() {
		console.log(this.yesterdayPushedList)
	},
	methods: {
		showImage(images, index) {
			ImagePreview({
				images: images, // 预览图片的那个数组
				showIndex: true,
				loop: false,
				startPosition: index // 指明预览第几张图
			})
		},
		async handleResend(val) {
			try {
				val.retryCount++
				Notify({
					type: 'success',
					message: '重新发送请求已发起！'
				})
				await ApiFriendCircleRetrySend(val.msgCode)
			} catch (e) {
				console.warn(e)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.push-box {
	margin: 15px;
	padding: 10px 15px;
	background: white;
	border-radius: 10px;
}

.item {
	padding: px(15) 0;
	border-bottom: px(1) #f1f1f1 dashed;
	font-size: px(14);
	display: flex;

	.headImg {
		width: px(44);
		height: px(44);
		overflow: hidden;
		border-radius: 50%;
		margin-right: px(6);

		img {
			width: 100%;
			height: 100%;
			display: block;
		}
	}

	.text {
		flex: 1;

		.name {
			display: flex;
			margin-bottom: px(5);
			align-items: center;

			p {
				flex: 1;
				margin: 0;
				color: #35557c;
				font-size: px(14);
				line-height: px(20);
			}

			.btn {
				color: #35557c;
				font-size: px(12);
				margin-right: px(5);

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.con {
			margin-bottom: px(15);
		}

		.link {
			display: flex;
			align-items: center;
			background-color: #f6f6f6;
			border-radius: px(4);
			padding: px(7);
			margin-bottom: px(15);

			.linkImg {
				width: px(44);
				height: px(44);
				margin-right: px(5);
				border-radius: px(2);
				overflow: hidden;

				.van-image {
					width: 100%;
					height: 100%;
				}

				img {
					width: 100%;
					height: 100%;
					display: block;
				}
			}

			.linkTitle {
				flex: 1;
			}
		}

		.time {
			display: flex;
			align-items: center;

			p {
				font-size: px(12);
				flex: 1;
				margin: 0;
			}

			.btn {
				color: #35557c;
				font-size: px(12);
			}
		}
	}

	&:last-child {
		border-bottom: 0;
	}
}

.van-grid {
	margin: px(10) 0;
}
</style>
