<script setup>

import ShowExplain from "./ShowExplain.vue";
import WxComment from "./WxComment";
import {ImagePreview} from 'vant'
import {computed, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
const store = useStore()
const props = defineProps({
  item: {
    type: Object,
    default: () => {
    }
  }
})
const robotInfo = computed(() => {
  return store.getters.robotInfo
})
const showImage = (images, index) => {
  ImagePreview({
    images: images, // 预览图片的那个数组
    showIndex: true,
    loop: false,
    startPosition: index // 指明预览第几张图
  })
}
</script>

<template>
  <div class="friend-info">
    <!--视频号  其文本内容存放到desc中-->
    <template v-if="[2017, 2019].includes(item.msgType)">
      <show-explain v-if="item.linkDesc" :key="item.msgId" :content="item.linkDesc"/>
      <div class="mini-program video-count">
        <div class="mp-p1 flex">
          {{ item.videoNumberMsg.nickName }}
        </div>
        <div class="mp-p2">
          <img :src="item.videoNumberMsg.coverUrl" mode="aspectFill" class="mp-p2-1">
        </div>
        <div class="mp-p3">
          <div class="mp-p3-1"/>
          <div>{{ item.msgType === 2017 ? '视频号' : '直播' }}</div>
        </div>
      </div>
    </template>

    <template v-else>
      <show-explain v-if="item.content" :key="item.msgId" :content="item.content"/>
      <van-grid v-if="item.picUrl.length > 0"
                :column-num="3"
                :border="false">
        <van-grid-item v-for="(src, index) in item.picUrl"
                       :key="index">
          <van-image :src="src" height=".9rem"
                     lazy-load fit="scale-down"
                     @click="showImage(item.picUrl, index)"/>
        </van-grid-item>
      </van-grid>
    </template>
    <wx-comment :name="robotInfo.nickName" v-if="item.comment" :comment="item.comment"/>
  </div>
</template>

<style scoped lang="scss">
.video-count {
  width: px(203);
  background: #F6F6F6;
  border-radius: px(4);
  margin-bottom: 10px;

  .mp-p0 {
    margin-top: px(-5);
    padding: 0 px(10) px(10);
    color: #999;
    line-height: 1.4;
  }

  .mp-p1 {
    padding: px(10);
    line-height: 1.4;
  }

  .mp-p2 {
    padding: 0 px(10) px(10);
  }

  .mp-p2-1 {
    display: block;
    width: 100%;
    height: 109px;
    border-radius: px(4);
    object-fit: cover;
  }

  .mp-p3 {
    display: flex;
    align-items: center;
    border-top: solid px(1) #E7E7E7;
    padding: px(5) px(10);
    color: #999;
    font-size: px(10);
  }

  .mp-p3-1 {
    margin-right: px(5);
    width: px(10);
    height: px(10);
    background: url("./msg-box/assets/icon-2.png") center no-repeat;
    background-size: contain;
  }
}

</style>
