import myAxios from '../utils/request'

// 是否开启朋友圈推送
const ApiIsOpenPush = () => {
  return myAxios.post('/FriendCircle/IsOpenPush')
}

// 开启朋友圈推送
const ApiSetOpenPush = (isOpenPush ) => {
  return myAxios.post('/FriendCircle/SetOpenPush', {
    isOpenPush: isOpenPush
  })
}

// 设置跟发状态
const ApiSetNotPushMsg = (data = {}) => {
  return myAxios.post('/FriendCircle/SetNotPushMsg', {
    // 消息ID
    msgId: data.msgId,
    // 消息Code
    msgCode: data.msgCode,
    // 是否推送
    isPush: data.isPush
  })
}
// 重置编辑过的数据
const ApiResetEditPushMsg = (msgId) => {
  return myAxios.post('/FriendCircle/ResetEditPushMsg', {
    msgId: msgId
  })
}

// 获取推送数据
const ApiGetPushMsg = (data = {}) => {
  return myAxios.post('/FriendCircle/GetPushMsg', {
    startDate: data.startDate,
    endDate: data.endDate,

  })
}


// 获取推送消息详情
const ApiGetPushMsgDetail = (msgId = '') => {
  return myAxios.get('/FriendCircle/GetPushMsgDetail', {
    params: {
      msgId: msgId
    }
  })
}

// 获取已经推送的数据
const ApiGetPushMsgRecord = (date = '') => {
  return myAxios.post('/FriendCircle/GetPushMsgRecord', {
    date: date
  })
}

// 重新编辑朋友圈消息
const ApiSaveFriendCirclePushMsg = (data = {}) => {
  return myAxios.post('/FriendCircle/SaveFriendCirclePushMsg', {
    //  消息ID
    msgId: data.msgId,
    //  商品链接
    productLink: data.productLink,
    //  替换链接
    replaceLink: data.replaceLink,
    //  内容
    content: data.content,
    // 推送具体时间,格式（2021-08-08 18:00）
    pushTime: data.pushTime,
    // 朋友圈类型（类型 2001文字 2002图文 2004视频 2005链接）
    msgType: data.msgType,
    // 图片（多张图片用,隔开，发布类型是2放链接封面图,链接图片一张，多图最多9张）
    picUrl: data.picUrl,
    // 图文链接简介
    linkDesc: data.linkDesc,
    //  图文链接图片
    linkPic: data.linkPic,
    // 图文链接地址
    linkAddress: data.linkAddress,
    // 是否推送
    isPush: data.isPush,
    // 图文链接地址
    id: data.id,
    // 是否允许编辑
    isEdit: data.isEdit
  })
}

// 删除已经推送的数据
const ApiDelPushMsgRecord = (id = '') => {
  return myAxios.post('/FriendCircle/DelPushMsgRecord', {
    id: id
  })
}

// 朋友圈重新发送
const ApiFriendCircleRetrySend = (msgCode = '') => {
  return myAxios.post('/FriendCircle/FriendCircleRetrySend', {
    msgCode: msgCode
  })
}

// 同步机器人标签信息
const ApiSyncRobotTags = (robotWxId = '') => {
  return myAxios.get('/FriendCircle/SyncRobotTags', {
    params: {
      robotWxId: robotWxId
    }
  })
}

// 获取微信标签信息
const ApiGetWxTagList = (robotWxId = '') => {
  return myAxios.post('/FriendCircle/GetWxTagList', {
    robotWxId: robotWxId
  })
}

// 用户朋友圈通用获取
const ApiGetFriendCircleSend = (robotWxId = '') => {
  return myAxios.post('/FriendCircle/GetFriendCircleSend', {
    robotWxId: robotWxId
  })
}

// 用户朋友圈通用设置
const ApiSetFriendCircleSend = (data = {}) => {
  return myAxios.post('/FriendCircle/SetFriendCircleSend', {
    robotWxId: data.robotWxId,
    // 可见类型 0：默认无限制 1：指定不可见 2：指定可见
    privateType: data.privateType,
    // 设置的标签ID以,隔开
    tagIds: data.tagIds,
  })
}
export {
  // 是否开启朋友圈推送
  ApiIsOpenPush,
  // 开启朋友圈推送
  ApiSetOpenPush,
  // 设置消息不推送
  ApiSetNotPushMsg,
  // 重置编辑过的数据
  ApiResetEditPushMsg,
  // 获取待推送数据
  ApiGetPushMsg,
  // 获取推送消息详情
  ApiGetPushMsgDetail,
  // 获取已经推送的数据
  ApiGetPushMsgRecord,
  // 重新编辑朋友圈消息
  ApiSaveFriendCirclePushMsg,
  // 删除已经推送的数据
  ApiDelPushMsgRecord,
  // 朋友圈重新发送
  ApiFriendCircleRetrySend,
  // 同步机器人标签信息
  ApiSyncRobotTags,
  // 获取微信标签信息
  ApiGetWxTagList,
  // 用户朋友圈通用设置
  ApiGetFriendCircleSend,
  // 用户朋友圈通用设置
  ApiSetFriendCircleSend,
}
